<template>
  <div>
    <div class="col-12">
      <div class="h6 fw-bold mt-3">テンプレート設定</div>
      <div id="module-cover-page-templates-selected" class="list-group list-group-horizontal">
        <label class="list-group-item col-4 text-center">
          <input class="form-check-input me-1 mb-2" type="radio" name="templateSelector" value="1" v-model="coverPageData.templates.selected" @change="onChange">
          1. 左揃え
        </label>
        <label class="list-group-item col-4 text-center">
          <input class="form-check-input me-1 mb-2" type="radio" name="templateSelector" value="2" v-model="coverPageData.templates.selected" @change="onChange">
          2. 中央揃え
        </label>
        <label class="list-group-item col-4 text-center">
          <input class="form-check-input me-1 mb-2" type="radio" name="templateSelector" value="3" v-model="coverPageData.templates.selected" @change="onChange">
          3. 右揃え
        </label>
      </div>
    </div>

    <div class="h6 fw-bold mt-4">コンテント設定</div>
    <editor
      v-model="coverPageData.content.body"
      @onKeyUp='onChange'
      @onMouseUp='onChange'
      @onChange='onChange'
      api-key="ioly5ghskf2uk5id8qhz3rlw9qn4d69n7ecj5tn9f7uqegg2"
      :init="{
        language: 'ja',
        height: 500,
        menubar: true,
        toolbar_mode: 'wrap',
        content_style: `
          @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
          @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap');
          body { font-family: Noto Sans JP !important; };
        `,
        font_formats: 'Noto Sans JP=Noto Sans JP; Noto Serif JP=Noto Serif JP',
        style_formats: [
          {title: 'ウェイト', items: [
            {title: '900', inline: 'span', styles: {'font-weight': '900'}},
            {title: '800', inline: 'span', styles: {'font-weight': '800'}},
            {title: '700', inline: 'span', styles: {'font-weight': '700'}},
            {title: '600', inline: 'span', styles: {'font-weight': '600'}},
            {title: '500', inline: 'span', styles: {'font-weight': '500'}},
            {title: '400', inline: 'span', styles: {'font-weight': '400'}},
            {title: '300', inline: 'span', styles: {'font-weight': '300'}},
            {title: '200', inline: 'span', styles: {'font-weight': '200'}},
            {title: '100', inline: 'span', styles: {'font-weight': '100'}}
          ]},
          {title: 'ドロップシャドウ', items: [
            {title: 'ブラック', inline: 'span', styles: {'text-shadow': 'rgb(0 0 0 / 65%) 0px 3px 8px'}},
            {title: 'ホワイト', inline: 'span', styles: {'text-shadow': 'rgb(255 255 255 / 65%) 0px 3px 8px'}}
          ]}
        ],
        style_formats_merge: true,
        plugins: [
          'print preview paste searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons'
        ],
        toolbar:
          'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect styleselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media template link anchor codesample | ltr rtl'
      }"
    />
    <div class="field mt-3">
      <label class="form-label">再生ボタン用テキスト</label>
      <input id="module-cover-page-content-play-button-text" class="form-control mb-2" type="text" placeholder="VRショーケースへ入場する" v-model="coverPageData.content.play_button_text" @keyup="onChange" @input="onChange"/>

      <input
        v-model='coverPageData.content.play_button_bottom'
        class='form-check-input'
        id='module-cover-page-content-play-button-only'
        type='checkbox'
        @change='onChange'
      >
      <label class='form-check-label' for='module-cover-page-content-play-button-only'>再生ボタンを下部に表示</label>
      <i class="bi bi-question-circle-fill text-secondary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="コンテントの中身があると再生ボタンは必ず下部に表示されます。"></i>
    </div>
    <div class="field mt-3">
      <label class="form-label">背景カラー</label>
      <input id="module-cover-page-content-bg-color" class="form-control" type="input" placeholder="#FFFFFFFF / rgb(255 255 255 / 100%)" v-model="coverPageData.content.background.color" @keyup="onChange" @input="onChange"/>
    </div>

    <div class="h6 fw-bold mt-4">背景画像設定</div>
    <div class="row">
      <div class="col-12">
        <div class="field mt-3">
          <label class="form-label">背景動画URL</label>
          <i class="bi bi-question-circle-fill text-secondary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="URLを入力するかファイルをアップロードしてください。両方指定されている場合はファイルが優先されます。アップロード可能な動画は30MB以下のmp4、webmです。"></i>
          <input id="module-cover-page-bg-video-url" class="form-control" type="text" placeholder="http://xxx.com/background-video.mp4" v-model="coverPageData.background.video.url" @keyup="onChange" @input="onChange"/>
          <input class="form-control my-2" type='file' id='showcase_cover_page_background_video' name='showcase[cover_page_background_video]'/>

          <input
            class='form-check-input'
            id='module-cover-page-bg-video-loop'
            type='checkbox'
            v-model='coverPageData.background.video.loop'
            @change='onChange'
          >
          <label class='form-check-label' for='module-cover-page-bg-video-loop'>ループ再生をする</label>
          <i class="bi bi-question-circle-fill text-secondary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="オフの場合、動画の最終フレームで停止した状態になります。"></i>

          <div class="cover_page_background_video_preview mt-2" v-if='previewVideoUrl'>
            <div class="form-label mb-1">
              プレビュー
              <div class="btn btn-sm btn-danger ms-2" @click='onPurgeVideo' v-if='showPurgeVideoButton'>
                <i class="bi bi-trash-fill" />
              </div>
            </div>
            <video class="img-fluid" autoplay loop controls playsinline muted :src='previewVideoUrl'/>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="field mt-3">
          <label class="form-label">画像URL</label>
          <i class="bi bi-question-circle-fill text-secondary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="URLを入力するかファイルをアップロードしてください。両方指定されている場合はファイルが優先されます。動画URLの指定がある場合はそちらが優先されます。アップロード可能な画像は5MB以下のpng、jpg、gifです。"></i>
          <input class="form-control" id="module-cover-page-bg-image-url" type="text" placeholder="http://xxx.com/background-image.png" v-model="coverPageData.background.image_url" @keyup="onChange"/>
          <input class="form-control mt-2" type='file' id='showcase_cover_page_background' name='showcase[cover_page_background]'/>
          <div class="cover_page_background_preview mt-2" v-if='previewImageUrl'>
            <div class="form-label mb-1">
              プレビュー
              <div class="btn btn-sm btn-danger ms-2" @click='onPurgeImage' v-if='showPurgeImageButton'>
                <i class="bi bi-trash-fill" />
              </div>
            </div>
            <img class="img-fluid" :src='previewImageUrl'/>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="field mt-3">
          <label class="form-label">サイズ</label>
          <i class="bi bi-question-circle-fill text-secondary" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-html="true" title="background-sizeのスタイルを指定出来ます。詳しくは<a href='https://developer.mozilla.org/ja/docs/Web/CSS/background-size' target='_blank'>こちら</a>から。"></i>
          <input class="form-control" id="module-cover-page-bg-size" type="text" placeholder="unset" v-model="coverPageData.background.size" @keyup="onChange"/>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="field mt-3">
          <label class="form-label">リピート</label>
          <i class="bi bi-question-circle-fill text-secondary" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-html="true" title="background-repeatのスタイルを指定出来ます。詳しくは<a href='https://developer.mozilla.org/ja/docs/Web/CSS/background-repeat' target='_blank'>こちら</a>から。"></i>
          <input class="form-control" id="module-cover-page-bg-repeat" type="text" placeholder="unset" v-model="coverPageData.background.repeat" @keyup="onChange"/>
        </div>
      </div>
      <div class="col-12 col-sm-4">
        <div class="field mt-3">
          <label class="form-label">ポジション</label>
          <i class="bi bi-question-circle-fill text-secondary" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-html="true" title="background-positionのスタイルを指定出来ます。詳しくは<a href='https://developer.mozilla.org/ja/docs/Web/CSS/background-position' target='_blank'>こちら</a>から。"></i>
          <input class="form-control" id="module-cover-page-bg-position" type="text" placeholder="unset" v-model="coverPageData.background.position" @keyup="onChange"/>
        </div>
      </div>
    </div>

    <div class="h6 fw-bold mt-4 mb-0">背景パターン設定
      <i class="bi bi-question-circle-fill text-secondary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="動画・画像URLの指定がある場合はそちらが優先されます。"></i>
    </div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="field mt-3">
          <label class="form-label">カラー</label>
          <i class="bi bi-question-circle-fill text-secondary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="パターンカラーを指定してください。「,」区切りでグラデーテョンを指定する事も可能です。"></i>
          <input class="form-control" id="module-cover-page-bg-trianglify-colors" type="text" placeholder="単色「#cccccc」、グラデーション「#000000,#ffffff」" v-model="coverPageData.background.trianglify.color" @keyup="onChange"/>
        </div>
      </div>
      <div class="col-12 col-sm-3">
        <div class="field mt-3">
          <label class="form-label">セルサイズ</label>
          <i class="bi bi-question-circle-fill text-secondary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="セルサイズを指定してください。"></i>
          <input class="form-control" id="module-cover-page-bg-trianglify-size" type="text" placeholder="75" v-model="coverPageData.background.trianglify.size" @keyup="onChange"/>
        </div>
      </div>
      <div class="col-12 col-sm-3">
        <div class="field mt-3">
          <label class="form-label">セルサイズ変動値</label>
          <i class="bi bi-question-circle-fill text-secondary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="セルサイズの変動値を指定してください。値が大きいほどセルサイズの変化量が上がります。"></i>
          <input class="form-control" id="module-cover-page-bg-trianglify-variance" type="text" placeholder="0.75" v-model="coverPageData.background.trianglify.variance" @keyup="onChange"/>
        </div>
      </div>
    </div>
    <div class='form-check mt-4'>
      <input
        class='form-check-input'
        id='module-cover-page-data-first'
        type='checkbox'
        v-model='coverPageData.first'
        @change='onChange'
      >
      <label class='form-check-label fw-bold' for='module-cover-page-data-first'>カバーページを最初に表示する</label>
      <i class="bi bi-question-circle-fill text-secondary" data-bs-toggle="tooltip" data-bs-placement="bottom" title="ゲストブックと併用している時にチェックを入れなかった場合、ゲストブックが最初に表示されます。"></i>
    </div>
  </div>
</template>
<script>

import axios from 'axios'
import Editor from '@tinymce/tinymce-vue'

export default {
  data() {
    return {
      coverPageData: {},
      previewImageUrl: this.coverPageBackgroundUrl,
      previewVideoUrl: this.coverPageBackgroundVideoUrl,
      showPurgeImageButton: this.purgeImageUrl,
      showPurgeVideoButton: this.purgeVideoUrl
    }
  },
  props: ['moduleCoverPage', 'coverPageBackgroundUrl', 'coverPageBackgroundVideoUrl', 'purgeImageUrl', 'purgeVideoUrl'],
  created() {
    const parsedCoverPage = JSON.parse(this.moduleCoverPage)
    for (let key in parsedCoverPage) {
      this.$set(this.coverPageData, key, parsedCoverPage[key])
    }
  },
  mounted() {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }
  },
  components: {
    editor: Editor
  },
  methods: {
    onChange() {
      document.getElementById('showcase_module_cover_page_data').value = JSON.stringify(this.coverPageData)
    },
    onPurgeImage() {
      const result = confirm('画像ファイルを削除しますがよろしいですか？')
      if (result) {
        axios.delete(this.purgeImageUrl)
        this.previewImageUrl = this.coverPageData.background.image_url || null
        this.showPurgeImageButton = false
      }
    },
    onPurgeVideo() {
      const result = confirm('動画ファイルを削除しますがよろしいですか？')
      if (result) {
        axios.delete(this.purgeVideoUrl)
        this.previewVideoUrl = this.coverPageData.background.video.url || null
        this.showPurgeVideoButton = false
      }
    }
  }
}
</script>