var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("editor", {
        attrs: {
          "api-key": "ioly5ghskf2uk5id8qhz3rlw9qn4d69n7ecj5tn9f7uqegg2",
          init: {
            language: "ja",
            height: 500,
            menubar: true,
            plugins: [
              "print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons"
            ],
            toolbar:
              "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | insertfile image media template link anchor codesample | ltr rtl"
          }
        },
        on: {
          onKeyUp: _vm.onChange,
          onMouseUp: _vm.onChange,
          onChange: _vm.onChange
        },
        model: {
          value: _vm.privacyPolicyData.body,
          callback: function($$v) {
            _vm.$set(_vm.privacyPolicyData, "body", $$v)
          },
          expression: "privacyPolicyData.body"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }